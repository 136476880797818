import styles from './index.module.scss';

export const Hook = () => {
  return (
    <div className={styles.panel}>
      <div className={styles.content}>
        <h2 className={styles.title}>
          Interesting problems deserve good conversations.
        </h2>
        <div className={styles.info}>
          <span>San Jose, CA</span>
          <span>hello@davidsainez.com</span>
          <span>@davidsainez</span>
        </div>
      </div>
    </div>
  );
};
