import { FC } from 'react';
import Link from 'next/link';
import { useInView, animated } from '@react-spring/web';
import { RiArrowRightLine } from 'react-icons/ri';
import styles from './index.module.scss';

type IndexCardProps = {
  href: string;
  title: string;
  date: string;
  summary: string;
  tags: Array<string>;
};

export const IndexCard: FC<IndexCardProps> = ({
  href,
  title,
  date,
  summary,
  tags,
}) => {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 100,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      once: true,
      rootMargin: '-20% 0%',
    }
  );

  const dateobj = new Date(date);
  const formattedDate = dateobj
    .toLocaleDateString('en-US', {
      month: 'short', // "Aug"
      day: '2-digit', // "12"
      year: 'numeric', // "2022"
    })
    .replace(',', '');

  const Tags = tags.map((tag, i) => (
    <>
      <span key={tag} className={styles.tag}>
        {tag}
      </span>
      {i < tags.length - 1 && '·'}
    </>
  ));

  return (
    <animated.div ref={ref} style={springs} className={styles.box}>
      <span className={styles.date}>{date}</span>
      <h2 id={styles.title}>
        <Link href={href}>{title}</Link>
      </h2>
      <div className={styles.description}>{summary}</div>
      <div className={styles.read}>
        Read article <RiArrowRightLine />
      </div>
    </animated.div>
  );
};
