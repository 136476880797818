import { useState, useEffect } from 'react';
import styles from './index.module.scss';

const useTypewriter = (text: string, delay = 200) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setIndex(index + 1);
      }, delay);
      return () => clearTimeout(timeout);
    }
  }, [index, text, delay]);

  return {
    displayText: text.substring(0, index) + '|' + text.substring(index),
    isComplete: index === text.length,
  };
};

export const Welcome = () => {
  const name = 'David Sainez.';
  const { displayText, isComplete } = useTypewriter(name);

  return (
    <div className={styles.panel}>
      <div className={styles.content}>
        <div className={styles.title}>
          My name is{' '}
          <span className={styles.typingContainer}>
            {displayText.split('|').map((part, i) => (
              <span key={i} className={i === 0 ? styles.typed : styles.untyped}>
                {part}
                {i === 0 ? <span className={styles.cursor}>|</span> : undefined}
              </span>
            ))}
          </span>
        </div>
        <div
          className={styles.description}
          style={{
            opacity: isComplete ? 1 : 0,
            transform: isComplete ? 'translateY(0)' : 'translateY(10px)',
          }}
        >
          I write about software design, AI and web technology.
        </div>
      </div>
    </div>
  );
};

/*
export const Welcome = ({}) => {
  return (
    <div className={styles.panel}>
       <div className={styles.content}>
           <div className={styles.title}>
             My name is David Sainez.
           </div>
           <div className={styles.description}>
             I write about software design, AI and web technology.
           </div>
       </div>
   </div>
  );
};
 */
