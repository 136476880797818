import { FC } from 'react';
import Link from 'next/link';
import styles from './index.module.scss';

type HeaderProps = {
  white: boolean;
};

export const Header: FC<HeaderProps> = ({ white }) => {
  //{/*<div className={white ? styles.whitePanel : styles.colorPanel}>*/}
  return (
    <div className={styles.panel}>
      <div className={styles.background}>
        <div className={styles.content}>
          <div className={styles.menu}>
            <div className={styles.brand}>
              <Link href="/" className={styles.spacer}>
                DavidSainez
              </Link>
            </div>
            <div className={styles.nav}>
              <Link href="/">Home</Link>
              <Link href="/essays/me">About</Link>
            </div>
          </div>
          <div className={styles.contact}>hello@davidsainez.com</div>
        </div>
      </div>
    </div>
  );
};
