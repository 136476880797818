import { FC } from 'react';
import { NextSeo } from 'next-seo';
import { IndexCard } from 'components/IndexCard';
import { Template } from 'components/Template';
import { Metadata } from 'Data';
import { Welcome } from './Welcome';
import styles from './landingPage.module.scss';

const TITLE = 'David Sainez';
const URL = 'davidsainez.com';
const DESCRIPTION = `
David Sainez's collection of writings exploring mental models, software design, and web technology.
`;

type LandingPageProps = {
  essays: Array<Metadata>;
};

export const LandingPage: FC<LandingPageProps> = ({ essays }) => {
  const cards = [...essays]
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime(); // Sort in descending order
    })
    .map((essay) => (
      <IndexCard
        key={essay.href}
        href={essay.href}
        title={essay.title}
        date={essay.date}
        summary={essay.summary}
        tags={essay.tags}
      />
    ));

  return (
    <Template white={false}>
      <NextSeo
        title={TITLE}
        description={DESCRIPTION}
        openGraph={{
          url: URL,
          title: TITLE,
          description: DESCRIPTION,
        }}
      />
      <div className={styles.all}>
        <Welcome />
        <div className={styles.panel}>
          <div className={styles.headingBox}>
            <h2 className={styles.heading}>Latest Writing</h2>
          </div>
          <div className={styles.cardGrid}>{cards}</div>
        </div>
      </div>
    </Template>
  );
};
